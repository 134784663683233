.orgchart.myChart {
    /* background-image: linear-gradient( 90deg, rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important; */
    background-image: none !important;
}

.orgchart.myChart>ul>li>ul li::before {
    border-top-color: #cbcbcb;
}

.orgchart.myChart>ul>li>ul li .oc-node::before, .orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #cbcbcb;
}

.orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: #0b0a33;
    color: #fff;
    min-width: 170px;
    min-height: 25px;
    padding: 2px;
}

.orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    color: #939393;
    background-color: #fff;
    min-width: 170px;
    /* min-height: 65px; */
    padding: 2px;
    border: 1px solid #b9b9b9;
    word-break: break-all;
    border-top: none;
}

.orgchart.myChart .oc-node .groupsales {
	display: flex;
	box-sizing: border-box;
	color: #939393;
	background-color: #fff;
	min-width: 170px;
}

.orgchart.myChart .oc-node .groupsales div:first-child {
	flex:1;
	border-left: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    align-items: center;
    justify-content: center;
    display: flex;
}
.orgchart.myChart .oc-node .groupsales div:nth-child(2) {
	flex: 1;
	border-left: 1px solid #b9b9b9;
	border-right: 1px solid #b9b9b9;
	border-bottom: 1px solid #b9b9b9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.svg-chart-container {
    width: 100%;
    height: 100%;
}

.tree-container {
    padding:10px;
    border-radius:10px;
    /* border: 1px solid #E4E2E9 */
}

.tree-avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.up-arrow-container {
    margin-top: -40px;
    margin-bottom: 10px;
    font-size: 20px;
    text-decoration: none;
    width: 100%;
    text-align: center;
}

.tree-avatar {
    width: 55px;
    height: 55px;
    border-radius: 60px;
}

.tree-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
}

.display-container {
    display: flex;
    flex-direction: row;
    width: 100%
}

.title-username {
    font-size: 20px;
    padding: 5px;
}

.left-right-container {
    flex: 1;
    text-align: center;
    border: 1px solid white;
    padding: 3px;
}

.expired-container {
    width: 100%;
    border: 1px solid #ffffff45;
    text-align: center;
    padding: 3px;
}

.empty-container {
    width: 100%;
    text-align: center;
    padding: 3px;
}