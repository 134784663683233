body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111115 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-gold {
  background: linear-gradient(280deg, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-white{
  color:#ffffff;
}

.gold-gradient-button-black-text {
  background: linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box;
  border-width: 1px;
  border-color: transparent;
  color:#000000 !important;
}

.gold-gradient-button-white-text {
  background: linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box;
  border-width: 1px;
  border-color: transparent;
  color:#ffffff !important;
}

.gold-background {
  background: linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box;
  color: 'transparent';
}

.gradient-border-box {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top 47px center;
  border: 2px solid transparent;
  background:
    linear-gradient(#111115,#111115) padding-box,
    linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f);
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
  border: 2px solid transparent; /* Adds padding around the thumb */
  background: linear-gradient(130deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #D4A964; /* Darker color when hovering over the thumb */
}